import { Logger } from "@webmonitoring/vp-common/Logger";
import Link from "@/components/UI/Link/Link";
import Badge from "@/components/UI/Badge/Badge";
import { getAllPublicPages } from "@/utils/api/cms-service/public-pages";
import { PublicPageZip, getPageUrl, toPublicPagesZip } from "@/utils/page";
import { createSession } from "@/utils/api/cognito/session";
import { getAllJobs } from "@/utils/api/job-service/jobs";
import { getAllMarketingPages } from "@/utils/api/cms-service/marketing-pages";
import { internalConfig } from "@/InternalConfig";

interface InternalDirectoryPageProps {
  marketingPages: any[];
  publicPagesZip: PublicPageZip[];
}

export default function InternalDirectoryPage({
  marketingPages,
  publicPagesZip,
}: InternalDirectoryPageProps) {
  return (
    <div>
      <div className="container mx-auto flex flex-col gap-8 px-4 py-24">
        <div className="flex flex-col gap-4">
          <div className="text-xl font-bold">Marketing pages directory</div>
          <div className="text-sm text-vpGray-700">
            This directory for internal releases only
          </div>
        </div>
        {(() => {
          if (!marketingPages.length) {
            return <div>No marketing pages found</div>;
          }

          return (
            <ul className="divide-y divide-vpGray-300">
              {marketingPages?.map((item) => {
                const defaultLocale = "en";

                const marketingPagePathname =
                  item?.attributes?.locale === defaultLocale
                    ? `/${item?.attributes?.Slug}`
                    : `/${item?.attributes?.locale}/${item?.attributes?.Slug}`;

                return (
                  <li key={item.id}>
                    <Link
                      className="block hover:bg-vpGray-200"
                      href={marketingPagePathname}
                      target="_blank"
                      variant="external"
                    >
                      <div className="flex items-center px-2 py-4 text-black">
                        <div className="flex min-w-0 flex-1 items-center">
                          <div className="flex flex-col gap-4 px-4">
                            <div className="flex flex-col gap-1">
                              <div className="flex gap-1">
                                <div className="font-bold">Friendly name:</div>
                                <div>{item?.attributes?.FriendlyName}</div>
                              </div>
                              <div className="flex gap-1">
                                <div className="font-bold">
                                  Marketing page url:
                                </div>
                                <div>{marketingPagePathname}</div>
                              </div>
                            </div>
                            <div className="flex gap-2">
                              <Badge
                                className="lowercase"
                                color="neutral500"
                                variant="solid"
                                rounded
                                size="medium"
                              >
                                Locale: {item?.attributes?.locale}
                              </Badge>
                              <Badge
                                className="lowercase"
                                color="neutral500"
                                variant="solid"
                                rounded
                                size="medium"
                              >
                                Slug: {item?.attributes?.Slug}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          );
        })()}
        <hr className="my-8" />
        <div className="flex flex-col gap-4">
          <div className="text-xl font-bold">Public pages directory</div>
          <div className="text-sm text-vpGray-700">
            This directory for internal releases only
          </div>
        </div>
        {(() => {
          if (!publicPagesZip.length) {
            return <div>No public pages found</div>;
          }

          return (
            <ul className="divide-y divide-vpGray-300">
              {publicPagesZip?.map((item) => {
                const publicPagePathname = getPageUrl({ page: item.page });

                return (
                  <li key={item.page?.id}>
                    <Link
                      className="block hover:bg-vpGray-200"
                      href={publicPagePathname}
                      target="_blank"
                      variant="external"
                    >
                      <div className="flex items-center px-2 py-4 text-black">
                        <div className="flex min-w-0 flex-1 items-center">
                          <div className="flex flex-col gap-4 px-4">
                            <div className="flex flex-col gap-1">
                              <div className="flex gap-1">
                                <div className="font-bold">Job url:</div>
                                <div>{item.job.url}</div>
                              </div>
                              <div className="flex gap-1">
                                <div className="font-bold">
                                  Job description:
                                </div>
                                <div>{item.job.description}</div>
                              </div>
                              <div className="flex gap-1">
                                <div className="font-bold">
                                  Public page url:
                                </div>
                                <div>{publicPagePathname.toString()}</div>
                              </div>
                            </div>
                            <div className="flex gap-2">
                              <Badge
                                className="lowercase"
                                color="neutral500"
                                variant="solid"
                                rounded
                                size="medium"
                              >
                                Locale: {item.page?.attributes?.locale}
                              </Badge>
                              <Badge
                                className="lowercase"
                                color="neutral500"
                                variant="solid"
                                rounded
                                size="medium"
                              >
                                Mode: {item.job.mode}
                              </Badge>
                              <Badge
                                className="lowercase"
                                color="neutral500"
                                variant="solid"
                                rounded
                                size="medium"
                              >
                                Interval: {item.job.interval}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          );
        })()}
      </div>
    </div>
  );
}

// Uncaught errors prevent page generation.
// See: https://nextjs.org/docs/pages/building-your-application/rendering/incremental-static-regeneration#error-handling-and-revalidation
export const getStaticProps = async () => {
  try {
    // This page should not be available in production environments.
    if (internalConfig.stage.isProd) {
      return {
        notFound: true,
      };
    }

    // A visualping session is required to make requests to visualping services.
    const session = await createSession({
      clientId: internalConfig.cognitoWebClientId,
      password: internalConfig.publicPagesBuildAgentPassword,
      username: internalConfig.publicPagesBuildAgentUsername,
    });

    const [jobsResponse, publicPagesResponse, marketingPagesResponse] =
      await Promise.all([
        getAllJobs({
          authorization: session?.AuthenticationResult.IdToken,
          workspaceId: internalConfig.publicPagesWorkspaceId,
        }),
        getAllPublicPages({
          locale: "all",
        }),
        getAllMarketingPages({
          locale: "all",
        }),
      ]);

    const publicPagesZip = toPublicPagesZip({
      jobs: jobsResponse.items,
      pages: publicPagesResponse.items,
    });

    // MetricPublisher.publishMetric(MetricName.PageBuildSuccess);

    return {
      props: {
        marketingPages: marketingPagesResponse?.items,
        publicPagesZip: publicPagesZip,
      },
      // See: https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration#error-handling-and-revalidation
      revalidate: 1,
    };
  } catch (error) {
    Logger.info("getStaticProps:error", { error });

    // MetricPublisher.publishMetric(MetricName.PageBuildError);

    throw error;
  }
};
